<template>
  <div class="banner-hero mb-5">
    <img class="banner-hero__cover" loading="lazy" :src="image" alt="" />
    <div class="banner-hero__content d-flex align-items-center">
      <section class="container py-lg-8 py-5">
        <div class="row justify-content-center">
          <div class="col-xl-8 col-lg-10 col-12">
            <div class="text-center d-flex flex-column gap-5">
              <div class="d-flex flex-column gap-3 mx-lg-8">
                <h1
                  v-if="title"
                  class="mb-3 display-5 home-title"
                  v-scroll-reveal
                >
                  {{ title }}
                </h1>
                <p
                  v-if="description && home"
                  class="mb-0 lead"
                  v-scroll-reveal="{ delay: 50 }"
                >
                  {{ description }}
                </p>
              </div>
              <div class="d-flex flex-row gap-2 justify-content-center">
                <router-link
                  v-if="cta && home"
                  to="/contacto"
                  class="button--kiiner"
                  v-scroll-reveal="{ delay: 80 }"
                >
                  {{ cta }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: "",
    },
    image: {
      default:
        "https://firebasestorage.googleapis.com/v0/b/knqun-8e329.appspot.com/o/assets%2Fpattern-square.svg?alt=media&token=7004ab63-6158-491d-bf80-355ceb19969e",
    },
    description: {
      default: "",
    },
    cta: {
      default: "",
    },
    home: {
      default: true,
    },
  },
};
</script>
