<template>
  <section class="our-benefits py-5">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-5">
          <h2 class="h3 our-benefits__title pb-4" v-scroll-reveal>
            {{ benefits.title }}
          </h2>
          <p
            class="text-medium"
            v-for="(p, index) in benefits.description"
            :key="index"
            v-scroll-reveal
          >
            {{ p }}
          </p>
        </div>
        <div class="col-12 col-lg-7">
          <div class="row pt-3 px-md-5">
            <div
              v-for="(item, index) in benefits.items"
              :key="index"
              class="col-12 col-lg-6 mb-lg-4"
            >
              <h4 class="mb-25" v-scroll-reveal>
                <ion-icon :name="item.icon"></ion-icon>
                {{ item.name }}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-center my-5 py-5">
          <router-link to="/contacto" class="button--kiiner" v-scroll-reveal>
            Trabajemos juntos
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    benefits: null,
  },
};
</script>
